import React from 'react';
import 'react-dropdown/style.css';
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Content from "../components/Content";

export function CaseStudyTemplate({content, featuredimage, title, contentComponent, helmet}) {
  const PostContent = contentComponent || Content
  return <>
    {helmet || ''}
    <div className={'case-study'}>
      <h1 className={'casestudy-title'}>{title}</h1>
      <div className={'image'}>
        <PreviewCompatibleImage imageInfo={{
          image: featuredimage,
          alt: 'Featured Image'
        }}/>
      </div>
      <PostContent className={'content'} content={content}/>
    </div>
  </>;
}

const CaseStudy = ({content, featuredimage, title, contentComponent}) => {
  return <div className={'container case-study'}>
    <CaseStudyTemplate
      content={content}
      featuredimage={featuredimage}
      title={title}
      contentComponent={contentComponent}
    />
  </div>
}

export default CaseStudy;


